import "core-js/modules/es.array.push.js";
import { xueQingActiveTableList } from "@/api/api";
export default {
  name: "xqActiveTableList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 20,
        current: 1,
        name: '',
        exam_time: '',
        creater: '',
        from: '',
        total: 0
      },
      exportIds: ''
    };
  },
  watch: {
    "$route.query.type": {
      handler: function (newVal) {
        this.pageInfo.from = newVal ? newVal : 'grade';
        this.getList();
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    let {
      type
    } = this.$root.useRoute.query;
    this.pageInfo.from = type || 'grade';
    this.getList();
  },
  methods: {
    reset() {
      let keys = ['name', 'exam_time', 'creater'];
      for (let key of keys) {
        this.pageInfo[key] = '';
      }
    },
    search() {
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      xueQingActiveTableList(this.pageInfo).then(res => {
        this.pageInfo.total = Number(res.data.total);
        this.pageInfo.current = Number(res.data.current_page);
        this.tableData = res.data.data || [];
      });
    },
    toInfo(item) {
      let path = `/xueqing/xqBoard/${item.id}`;
      if (this.pageInfo.from === 'class') {
        path = path + '?from=class';
      }
      this.$root.useRouter.push(path);
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};